<template>
  <div class="data-region-card">
    <div v-if="type === 'single'" @mousemove="move = true" @mouseleave="move = false" class="single-card" @click="$emit('visibleAlert')">
      <div class="single-card__top top">
        <div class="top__left" @click="nextRoute">
          <div class="top__left-user">
            <img :src="editImg.url" alt="" v-if="!startEdit"/>
            <user-avatar :data="editImg" @update="editImg = $event" v-else/>
            <p @click="!isAuth ? $router.push(`/work-requests?id=${data.id}&type=${data.name}`) : ''" class="city">{{ data.name }}</p>
          </div>
        </div>
        <div class="top__center">
          <div class="top__center-item">
            <p>{{ $t('damage-detected') }}</p>
            <span>{{ prettify(Math.round(+data.damage)) || 0 }} ₽</span>
          </div>
          <div class="top__center-item">
            <p>{{ $t('submitted-for-reimbursement') }}</p>
            <span>{{ prettify(Math.round(+data.damageClosed)) || 0 }} ₽</span>
          </div>
          <div class="top__center-item">
            <p>{{ $t('amount-of-investment') }}</p>
            <span>{{ prettify(Math.round(+data.investmentAmount)) || 0 }} ₽</span>
          </div>
        </div>
        <div class="top__right">
          <p class="top__right-count" v-html="$t('request-length', {from: data?.orders-data?.acceptedOrdersCount, to: data?.orders})"></p>
          <img
              @click="!isAuth ? visibleWindow = !visibleWindow : ''"
              src="@/assets/svg/arrows/up-black.svg"
              :class="['top__right-arrow', { rotate: visibleWindow }]"
          />
          <img @click="startEdit = !startEdit" v-if="edit && isEdit" class="top__right-edit" :src="require(`@/assets/svg/admin/edit-${color}.svg`)" alt="">
          <img src="@/assets/images/check-active.png" alt="" v-if="edit && !isEdit" class="top__right-edit" @click="updateRegionImage"/>
        </div>
      </div>
      <transition name="slide-top">
        <div class="single-card__bottom bottom" v-if="visibleWindow">
          <div class="bottom__item">
            <p class="bottom__item-left">{{ $t('data-region-card-index-1') }}</p>
            <p class="bottom__item-right">{{ getIndexNum(data.index1) }}</p>
          </div>
          <div class="bottom__item">
            <p class="bottom__item-left">{{ $t('data-region-card-index-2') }}</p>
            <p class="bottom__item-right">{{ getIndexNum(data.index2) }}</p>
          </div>
          <div class="bottom__item">
            <p class="bottom__item-left">{{ $t('data-region-card-index-3') }}</p>
            <p class="bottom__item-right">{{ getIndexNum(data.index3) }}</p>
          </div>
        </div>
      </transition>
    </div>
    <div @click="nextRoute" @mousemove="move = true" @mouseleave="move = false" v-else class="double-card">
      <div class="double-card__header header">
        <p class="header__title">{{ data.name }}</p>
        <div class="header__info info">
          <div class="info__left">
            <div class="info__left-item">
              <p>{{ $t('damage-detected') }}</p>
              <span>{{ prettify(Math.round(+data.damage)) || 0 }} ₽</span>
            </div>
            <div class="info__left-item">
              <p>{{ $t('submitted-for-reimbursement') }}</p>
              <span>{{ prettify(Math.round(+data.damageClosed)) || 0 }} ₽</span>
            </div>
            <div class="info__left-item">
              <p>{{ $t('amount-of-investment') }}</p>
              <span>{{ prettify(Math.round(+data.investmentAmount)) || 0 }} ₽</span>
            </div>
            <div class="info__left-item">
              <p>{{ $t('requests-close-total-1') }}</p>
              <span>{{ data?.orders-data?.acceptedOrdersCount }}/{{ data?.orders }}</span>
            </div>
          </div>
          <div class="info__right">
            <img :src="data.imageSrc.fileSrc || require('@/assets/images/event-card.png')" alt="" />
          </div>
        </div>
      </div>
      <div class="double-card__footer footer">
        <div class="footer__item">
          <p>{{ $t('index', {data: 1}) }}</p>
          <span>{{ getIndexNum(data.index1) }}</span>
        </div>
        <div class="footer__item">
          <p>{{ $t('index', {data: 2}) }}</p>
          <span>{{ getIndexNum(data.index2) }}</span>
        </div>
        <div class="footer__item">
          <p>{{ $t('index', {data: 3}) }}</p>
          <span>{{ getIndexNum(data.index3) }}</span>
        </div>
      </div>
    </div>
    <ui-alert v-model="showAlert">
      <p class="ui-alert__title">{{ $t('do-you-really-want-to-delete-region') }}</p>
      <div class="ui-alert__action">
        <ui-button @click="deleteRegion">{{ $t('yes') }}</ui-button>
        <ui-button @click="showAlert = false" color="error">{{ $t('no') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar.vue";
import {getCookie} from "@/utils/cookie";

export default {
  name: "DataRegionCard",
  components: {
    UserAvatar,
    UiButton: () => import('@/components/ui/UiButton'),
    UiAlert: () => import('@/components/ui/UiAlert'),
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'single'
    },
    edit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      move: false,
      startEdit:  false,
      editedCity: '',
      showAlert: false,
      editImg: {},
      isEdit: true,
      visibleWindow: false
    }
  },

  watch: {
    editImg () {
      this.isEdit = false
    }
  },

  methods: {
    prettify(num) {
      let n = num.toString();
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
    },
    deleteRegion() {
      this.showAlert = false
    },
    nextRoute () {
      !this.edit && !this.isAuth ? this.$router.push(`/work-requests?id=${this.data.id}&type=${this.data.name}`) : this.isAuth ? this.$emit('visibleAlert') : ''
    },
    updateRegionImage() {
      let payload = {
        id: this.data.id,
        image: this.editImg.filename
      }

      this.$emit('updateRegionImage', payload)
    },
    getIndexNum (index) {
      if (Number.isInteger(index)) {
        return index.toString().replace('.', ',')
      } else if (!Number.isInteger(index) && index){
          return index.toFixed(2).toString().replace('.', ',')
      } else {
          return 0
      }
    }
  },
  computed: {
    background() {
      return(
          this.data.rating === 'Unknown' ? '#BFBDBD' :
          this.data.rating === 'Low' ? '#E21F1F' :
          this.data.rating === 'Medium' ? '#FD9F32' :
          this.data.rating === 'High' ? '#5DC579' : ''
      )
    },
    totalScore() {
      return Math.round(this.data.score / this.data.ratingPercent)
    },
    scorePercent() {
      return this.data.ratingPercent === -1 ? '0' : Math.floor(this.data.ratingPercent * 100)
    },
    color() {
      return this.move ? 'red' : 'default'
    },

    isAuth () {
      return !getCookie('authToken') || this.$route.query.type === 'unconfirmed'
    }
  },
  mounted () {
    this.editedCity = this.data.name
  },

  created() {
    this.editImg.url = this.data.imageSrc.fileSrc || require('@/assets/images/event-card.png')
  }
}
</script>

<style lang="scss" scoped>
.single-card {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:hover {
    border-color: #CE2121;
    cursor: pointer;
  }

  .top {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 12px 20px 11px 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 200px;
    transition: .3s;
    border: 1px solid transparent;

    &__left {
      display: flex;
      align-items: center;
      gap: 24px;
      max-width: 379px;
      width: 100%;

      @media (max-width: 1400px) {
        max-width: 240px;
      }

      @media (max-width: 1200px) {
        max-width: 379px;
      }

      @media (max-width: 992px) {
        gap: 12px;
        max-width: 220px;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E21F1F;
        border-radius: 200px;
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #FFFFFF;
        padding: 12px 16px;

        @media (max-width: 992px) {
          display: none;
        }
      }

      &-user {
        display: flex;
        align-items: center;
        gap: 20px;
        max-width: 296px;
        width: 100%;
        position: relative;

        @media (max-width: 992px) {
          gap: 10px;
          max-width: 200px;
        }

        ::v-deep .ui-input {
          width: auto;
        }

        &-upload {
          position: absolute;
          left: 23px;
          top: 25px;
          width: auto !important;
          height: auto !important;
          border-radius: 1px !important;
          border: none !important;
          width: 32px;
          height: 32px;
        }

        img {
          min-width: 80px;
          min-height: 80px;
          max-width: 81px;
          max-height: 81px;
          border-radius: 50%;
          border: 1px solid #CE2121;
        }

        .city {
          font-family: "Gotham Pro Medium";
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #343432;
        }
      }
    }

    &__center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      width: 100%;

      &-item {
        display: grid;
        grid-template-columns: 206px auto;
        align-items: center;
        gap: 10px;

        @media (max-width: 1000px) {
          grid-template-columns: 140px auto;
        }

        p {
          color: #9A9A9A;
          font-family: 'Gotham Pro Regular';
          font-size: 14px;
          font-weight: 400;
          white-space: nowrap;

          @media (max-width: 1400px) {
            white-space: normal;
          }
        }

        span {
          color: #1B1A1F;
          font-family: 'Gotham Pro Regular';
          font-size: 14px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 35px;


      &-count {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120.2%;
        text-align: start;
        color: #9A9A9A;
        font-family: "Gotham Pro Regular";

        ::v-deep span {
          color: #1B1A1F;
          font-family: 'Gotham Pro Regular';
          font-size: 14px;
          font-weight: 400;
        }
      }

      &-percent {
        padding: 12px 27px;
        background: #5DC579;
        box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
        border-radius: 200px;
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #FFFFFF;
      }

      &-edit {
        width: 20px;
        height: 20px;
      }

      &-arrow {
        cursor: pointer;
        transform: rotate(180deg);
        transition: 0.5s;
      }

      .rotate {
        transform: rotate(0deg) !important;
      }
    }

    &__delete {
      position: absolute;
      top: 35px;
      left: -52px;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 20px;
    transition: .3s;
    margin: 0 21px;

    &__item {
      display: grid;
      grid-template-columns: 65% 35%;

      &-left {
        display: flex;
        align-items: center;
        border: 1px solid #F5F5F5;
        padding: 10px 20px;
        color: #1B1A1F;
        font-family: 'Gotham Pro Regular';
        font-size: 14px;
        font-weight: 400;
      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #F5F5F5;
        padding: 10px 20px;
        color: #1B1A1F;
        font-family: 'Gotham Pro Regular';
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
.double-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 490px;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid transparent;
  transition: .3s;
  height: 226px;

  @media (max-width: 768px) {
    height: auto;
  }

  @media (max-width: 1000px) {
    padding: 10px;
  }

  &:hover {
    border: 1px solid #E21F1F;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    @media (max-width: 768px) {
      gap: 5px;
    }

    &__title {
      color: #1B1A1F;
      font-size: 16px;
      font-weight: 400;
      white-space: nowrap;

      @media (max-width: 500px) {
        white-space: normal;
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &__left {
        display: flex;
        flex-direction: column;
        gap: 5px;
        &-item:last-child {
          margin-top: 10px;

          @media (max-width: 768px) {
            margin-top: 5px;
          }
        }

        &-item {
          display: grid;
          align-items: center;
          grid-template-columns: 208px auto;
          gap: 10px;

          @media (max-width: 500px) {
            grid-template-columns: 150px auto;
          }

          @media (max-width: 370px) {
            grid-template-columns: 2fr 1fr;
          }

          p {
            color: var(--grey-dark, #9A9A9A);
            font-family: 'Gotham Pro Regular';
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;

            @media (max-width: 500px) {
              font-size: 10px;
            }

            @media (max-width: 370px) {
              white-space: normal;
            }
          }

          span {
            color: #1B1A1F;
            font-family: 'Gotham Pro Regular';
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;

            @media (max-width: 500px) {
              font-size: 10px;
            }
          }
        }
      }

      &__right {
        img {
          width: 106px;
          height: 106px;
          object-fit: contain;

          @media (max-width: 500px) {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #F5F5F5;
    padding-top: 16px;

    @media (max-width:768px) {
      padding-top: 16px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        color: #9A9A9A;
        font-family: 'Gotham Pro Regular';
        font-size: 14px;
        font-weight: 400;

        @media (max-width: 500px) {
          font-size: 10px;
        }
      }

      span {
        color: #1B1A1F;
        font-family: 'Gotham Pro Regular';
        font-size: 14px;
        font-weight: 400;

        @media (max-width: 500px) {
          font-size: 10px;
        }
      }
    }
  }
}
</style>